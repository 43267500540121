import { find } from "rambda"

import type { TagCascadeSelectOption } from "./types"

const getParentPath = (list: TagCascadeSelectOption[], data: TagCascadeSelectOption, path: string): string => {
    const parent = find(item => item.value === data.parentId, list)
    if (!parent) {
        return path
    }
    const parentPath = `${parent.label}/${path}`
    if (parent.parentId) {
        return getParentPath(list, parent, parentPath)
    }
    return parentPath
}

export const getOptionWithPath = (list: TagCascadeSelectOption[]) => {
    return list.map(item => {
        const labelPath = getParentPath(list, item, item.label)
        return {
            ...item,
            labelPath
        }
    })
}
