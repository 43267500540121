import { Popover, Toast } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import type { DTFile } from '@lighthouse/core'
import { arrayMove } from '@lighthouse/tools'
import { useAbortAll } from '@rpldy/uploady'
import { current } from 'immer'
// import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { clone, concat, findIndex, isEmpty } from 'rambda'
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { useImmer } from 'use-immer'

import type { ApplicationPreviewEnum, EnableActionsParams, WithStatusFile } from '../../../types'
import { getFileNameByUrl, getFileSizeByUrl, getFileTypeByFileName, getFileTypeByUrl } from '../../../utils/helper'
import { FilePreviewer } from '../../FilePreviewer'
import { List } from '../../List'
import type { UseUploadFileSParameter } from '../../UploadManage'
import { useUploadBatchError, useUploadBatchFinished, useUploadBatchUploading } from '../../UploadManage'
import { FileUploader } from '../FileUploader'
import FileListItem from './FileListItem'
import * as SC from './styles'

export interface FileListProps {
    children?: React.ReactNode
    accept?: string
    isPexels?: boolean
    enableItemActions?: EnableActionsParams
    files: DTFile[]
    autoOpenWhenEmpty?: boolean
    previewType: ApplicationPreviewEnum
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    disableUpload?: boolean
    disableDownload?: boolean
    isUsedLink?: boolean
    popoverMainAxis?: boolean
    multiple?: boolean
    onChange?: (files: DTFile[]) => void
}

export const FileListPreviewer: React.FC<FileListProps> = ({
    accept,
    isPexels,
    files,
    enableItemActions,
    previewType,
    autoOpenWhenEmpty,
    disableUpload,
    uploadyOptions,
    disableDownload,
    isUsedLink,
    multiple,
    onChange
}) => {
    const [defaultIndex, setDefaultIndex] = useState(0)
    const [opened, setOpened] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)
    const listData = useMemo(() => {
        return files.map(url => {
            const name = getFileNameByUrl(url) || ''
            const type = getFileTypeByFileName(name)
            const size = getFileSizeByUrl(url)
            return {
                uid: '',
                label: name,
                value: url,
                size: Number(size),
                type,
                url,
                name: name ?? '',
                status: 'success',
                percent: 100
            }
        })
    }, [files])
    const [innerFiles, setInnerFiles] = useImmer(listData)
    const uploadDropId = useId()

    const newUploadyOptions = useMemo(
        () => ({ ...uploadyOptions, info: { ...uploadyOptions.info, id: uploadDropId } }),
        [uploadDropId, uploadyOptions]
    )

    const abortAll = useAbortAll()
    useEffect(() => {
        return () => {
            abortAll()
        }
    }, [abortAll])

    useUploadBatchUploading(uploadDropId, batch => {
        const { items: batchList } = batch
        const hasNotAllowFileType = accept && accept !== '*'
            ? batchList.some(item => {
                  const { file } = item
                  const fileType = getFileTypeByUrl(file.name)
                  return !accept.includes(fileType)
              })
            : false
        if (hasNotAllowFileType) {
            Toast.info('格式错误，仅支持上传图片')
            abortAll()
            return
        }
        const list = batchList.map(item => {
            const { file, id } = item
            const { name, type, size } = file
            const fileType = getFileTypeByUrl(name)
            return {
                uid: id,
                name,
                label: name,
                value: name,
                type: fileType,
                status: 'uploading',
                url: '',
                size,
                percent: 0
            }
        })
        const items = multiple ? list : list.slice(0, 1)
        setInnerFiles(draft => {
            for (const item of items) {
                draft.push(item)
            }
        })
    })

    useUploadBatchError(uploadDropId, batch => {
        const { items: batchList } = batch
        setInnerFiles(draft => {
            return draft.map(item => {
                const { uid } = item
                const uploadingFileIndex = findIndex(({ id }) => uid === id, batchList)
                if (uploadingFileIndex !== -1) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
                return item
            })
        })
    })

    useUploadBatchFinished(uploadDropId, batch => {
        const { items: batchList } = batch
        batchList.forEach(item => {
            const { id, uploadResponse } = item

            setInnerFiles(draft => {
                const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
                if (uploadingFileIndex !== -1 && uploadResponse.data.content) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.url = uploadResponse.data.content.url
                        uploadFile.percent = 100
                        uploadFile.status = 'success'
                    }
                    onChange?.(current(draft).map(file => file.url))
                } else {
                    setInnerFiles(draft => {
                        const uploadFile = draft[uploadingFileIndex]
                        if (uploadFile) {
                            uploadFile.status = 'error'
                            uploadFile.percent = 100
                        }
                    })
                }
            })
            setPopoverOpen(false)
        })
    })
    const handleFileAdd = useCallback(
        (file: WithStatusFile) => {
            setInnerFiles(draft => {
                const newFile = { ...file, label: file.name, uid: file.name, value: file.name, size: Number(file.size), percent: 100 }
                draft.push(newFile)
                onChange?.(concat(files, [newFile.url]))
            })
            setPopoverOpen(false)
        },
        [files, onChange, setInnerFiles]
    )

    const handleFileSort = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }

            const sourceIndex = findIndex(file => file === activeId, files)
            const targetIndex = findIndex(file => file === overId, files)

            const sortedFiles = arrayMove(files, sourceIndex, targetIndex)

            setInnerFiles(
                sortedFiles.map(url => {
                    const name = getFileNameByUrl(url) || ''
                    const type = getFileTypeByFileName(name)
                    const size = getFileSizeByUrl(url)
                    return {
                        uid: '',
                        label: name,
                        value: url,
                        type,
                        url,
                        name,
                        size: Number(size),
                        status: 'success',
                        percent: 100
                    }
                })
            )

            onChange?.(sortedFiles)
        },
        [files, onChange, setInnerFiles]
    )

    const handleFileRemove = useCallback(
        (index: number) => {
            setInnerFiles(draft => {
                draft.splice(index, 1)
                onChange?.(clone(draft).map(item => item.url))
            })
        },
        [onChange, setInnerFiles]
    )

    const handleFileEdit = useCallback(
        (index: number, name: string) => {
            setInnerFiles(draft => {
                const file = draft[index]
                if (file) {
                    draft.splice(index, 1, { ...file, name })
                    onChange?.(clone(draft).map(item => item.url))
                }
            })
        },
        [onChange, setInnerFiles]
    )

    // 如果没有文件，则默认打开文件上传器
    useMount(() => {
        if (autoOpenWhenEmpty && isEmpty(innerFiles)) {
            setPopoverOpen(true)
        }
    })

    useEffect(() => {
        return () => {
            setPopoverOpen(false)
        }
    }, [])

    const renderFileListItem = useCallback(
        (item: WithStatusFile, index: number) => {
            return (
                <FileListItem
                    enableActions={enableItemActions}
                    data={item}
                    previewType={previewType}
                    disableDownload={disableDownload}
                    onRemove={() => {
                        handleFileRemove(index)
                    }}
                    onChange={file => {
                        handleFileEdit(index, file)
                    }}
                    onPreview={() => {
                        setOpened(true)
                        setDefaultIndex(index)
                    }}
                />
            )
        },
        [disableDownload, enableItemActions, handleFileEdit, handleFileRemove, previewType]
    )

    const listContent = useMemo(() => {
        return innerFiles.length === 0 ? (
            <></>
        ) : (
            <List
                sortable
                rowKey="value"
                onSortEnd={handleFileSort}
                data={innerFiles}
                itemRender={(item, index) => renderFileListItem(item as WithStatusFile, index)}
            />
        )
    }, [handleFileSort, innerFiles, renderFileListItem])

    return (
        <SC.FileListWrapper>
            <SC.FileListContainer>{listContent}</SC.FileListContainer>
            {!disableUpload && (
                <Popover width="auto" withinPortal opened={popoverOpen} onChange={setPopoverOpen}>
                    <Popover.Target>
                        <SC.AddFileBtn>
                            <SC.AddIcon type="Add" />
                            <SC.AddBtnText>
                                添加文件<SC.UploadTip>（仅支持上传 300M 大小以内文件）</SC.UploadTip>
                            </SC.AddBtnText>
                        </SC.AddFileBtn>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <FileUploader
                            accept={accept}
                            multiple={multiple}
                            isPexels={isPexels}
                            isUsedLink={isUsedLink}
                            uploadyOptions={newUploadyOptions}
                            onImageLinkSave={handleFileAdd}
                        />
                    </Popover.Dropdown>
                </Popover>
            )}

            {/* <Popover
                {...bindPopover(popoverState)}
                // componentsProps={{
                //     backdrop: {
                //         style: { zIndex: 9999 }
                //     }
                // }}
                style={{ zIndex: 10_000 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >

            </Popover> */}
            <FilePreviewer defaultIndex={defaultIndex} fileList={innerFiles} opened={opened} onClose={() => setOpened(false)} />
        </SC.FileListWrapper>
    )
}
