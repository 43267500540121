import type { CascadeOption } from '@byecode/ui'
import { CascadeList, Flex, IconFont, Loading, Text, Tooltip } from '@byecode/ui'
import cls from 'classnames'
import { clone, filter, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import * as SC from './styles'
import type { TagCascadeSelectOption, TagCascadeSelectProps } from './types'
import { getOptionWithPath } from './utils'

export * from './types'

const getTreeOptions = (list: TagCascadeSelectOption[]) => {
    // 创建一个id到对象的映射
    const map = new Map()
    const arr: CascadeOption[] = []
    // 初始化映射
    list.forEach(item => {
        map.set(item.value, {...item})
    })
    // 构建树
    list.forEach(item => {
        if (item.parentId) {
            const parent = map.get(item.parentId)
            if (parent) {
                if (parent.children) {
                    parent.children.push(map.get(item.value))
                }
                if (!parent.children) {
                    {
                        parent.children = [map.get(item.value)]
                    }
                }
            }
            return
        }
        arr.push(map.get(item.value))
    })

    return arr
}

export const TagCascadeSelect = React.forwardRef<HTMLDivElement, TagCascadeSelectProps>(
    (
        { defaultSeaWord, multiple, loading, value: propsValue, options = [], autoFocus = true, tagItemRender, emptyItemRender, onChange },
        ref
    ) => {
        // const [value, setValue] = useState<string[]>(propsValue || [])
        const [keywords, setKeywords] = useState(defaultSeaWord || '')
        const cursorRef = useRef<HTMLDivElement>(null)

        const originOptions = useMemo(() => getOptionWithPath(options), [options])

        const filterTreeOptions = useMemo(() => {
            if (keywords.trim()) {
                return filter(option => option.labelPath?.includes?.(keywords), originOptions)
            }
            return getTreeOptions(originOptions)
        }, [keywords, originOptions])

        const clearInput = useCallback(() => {
            setKeywords('')
            cursorRef.current?.textContent && (cursorRef.current.textContent = '')
        }, [])

        const handleCascadeChange = useCallback(
            (val: string[]) => {
                onChange?.(val)
            },
            [onChange]
        )

        useHotkeys(
            'backspace',
            (event, handler) => {
                if (handler.key === 'backspace') {
                    if (cursorRef.current?.textContent) {
                        return
                    }
                    const newValue = clone(propsValue || [])
                    const deletedValue = newValue.pop()
                    if (deletedValue && onChange) {
                        onChange(newValue)
                    }
                }
            },
            { enableOnTags: ['INPUT'], enableOnContentEditable: true },
            []
        )

        const handleRemove = useCallback(
            (id: string) => {
                const newValue = propsValue?.filter(val => val !== id) || []
                handleCascadeChange?.(newValue)
            },
            [handleCascadeChange, propsValue]
        )

        const handleSearchValueChange = useCallback((label: string) => {
            setKeywords(label.trim())
        }, [])

        const inputFocus = useCallback(() => {
            cursorRef.current?.focus()
        }, [])

        const renderItem = useCallback(
            (column: CascadeOption) => {
                return (
                    <>
                        <SC.CascadeItemTitle

                            dangerouslySetInnerHTML={{
                                __html:
                                    keywords === ''
                                        ? column.label
                                        : column.labelPath?.replaceAll(
                                              keywords,
                                              `<span style="color: var(--color-app-main);">${keywords}</span>`
                                          ) ?? ''
                            }}
                        />
                        {propsValue?.includes(column.value) && (
                            <IconFont type="Tick" size={16} color="var(--byecode-colors-colorPrimary)" />
                        )}
                        {(column.children ?? []).length > 0 && <IconFont type="ArrowRightSmall" size={16} color="var(--color-gray-400)" />}
                    </>
                )
            },
            [keywords, propsValue]
        )

        const listView = loading ? (
            <Loading />
        ) : (
            <>
                <CascadeList
                    lastLevel={false}
                    columnWidth={keywords ? '100%' : 212}
                    data={propsValue}
                    multiple={multiple}
                    itemChildrenComponent={renderItem}
                    onChange={handleCascadeChange}
                    options={filterTreeOptions}
                />
            </>
        )

        useEffect(() => {
            defaultSeaWord || clearInput()
            // need clear input value As long as the state changes
        }, [clearInput, defaultSeaWord])

        useEffect(() => {
            // 聚焦问题 会引起移动端drawer动画问题
            if (autoFocus) {
                inputFocus()
            }
        }, [autoFocus, inputFocus])

        return (
            <SC.TagCascadeSelectWrapper
                ref={ref}
                onMouseDown={ev => {
                    ev.stopPropagation()
                }}
            >
                <SC.TagCascadeSelectSearch onClick={inputFocus}>
                    {propsValue?.map(id => {
                        const option = find(item => item.value === id, originOptions)
                        if (!option) {
                            if (emptyItemRender) {
                                return (
                                    <SC.TagItem key={id} className={cls({ 'plain-container': tagItemRender !== undefined })}>
                                        {emptyItemRender(id)}
                                        <SC.CloseIcon
                                            type="Close"
                                            onClick={() => {
                                                handleRemove(id)
                                            }}
                                        />
                                    </SC.TagItem>
                                )
                            }
                            return null
                        }

                        const { label, pathLabel } = option

                        return (
                            <Tooltip key={id} title={pathLabel}>
                                <SC.TagItem
                                    className={cls({ 'plain-container': tagItemRender !== undefined })}
                                    style={
                                        tagItemRender
                                            ? {}
                                            : {
                                                  borderColor: 'rgba(0,0,0,0.1)'
                                              }
                                    }
                                >
                                    {tagItemRender ? tagItemRender(option) : <SC.TagItemContent>{label}</SC.TagItemContent>}
                                    <SC.CloseIcon
                                        type="Close"
                                        onClick={() => {
                                            handleRemove(id)
                                        }}
                                    />
                                </SC.TagItem>
                            </Tooltip>
                        )
                    })}
                    <SC.Cursor
                        contentEditable
                        ref={cursorRef}
                        onInput={ev => handleSearchValueChange(ev.currentTarget.textContent ?? '')}
                    />
                </SC.TagCascadeSelectSearch>
                {listView}
            </SC.TagCascadeSelectWrapper>
        )
    }
)
